
























































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import Application from '@/models/application';
import { MerchantAPI } from '@/api';
import ApplicationResponse from '@/api/response/application';
import Organization from '@/models/organization';
import OrganizationResponse from '@/api/response/organizations';
import User from '../models/user';

@Component
export default class Applications extends Vue {
  protected apps: Application[] = [];
  protected total: number = 0;
  protected isLoading: boolean = false;
  protected sortField: string = 'id';
  protected sortOrder: string = 'desc';
  protected defaultSortOrder: string = 'desc';
  protected page: number = 1;
  protected perPage: number = 10;
  protected openedDetails: string[] = [];

  // On Production
  protected organizations: Organization[] = [];
  protected organization: Organization|null = null;
  protected loadingButton: boolean = false;

  public mounted() {
    this.loadAsyncData();

    MerchantAPI.organizationFind().then((organization: Organization) => {
      this.organization = organization;
    });
  }

  private user(): User | null {
    return this.$store.getters['auth/user'];
  }

  public userPayout(): boolean {
    const user = this.user();

    return user !== null && user.payout;
  }

  private toClipboard(key: string) {
    if (!window.isSecureContext) {
      this.$buefy.toast.open({
        duration: 5000,
        message: `Copy work only in
            <a href="https://w3c.github.io/clipboard-apis/#async-clipboard-api" target="_blank">secure context</a>
            (https or localhost).<br />
            Make sure you not use ip. Use localhost instead`,
        position: 'is-bottom',
        type: 'is-warning',
      });

      return;
    }

    navigator.clipboard.writeText(key).then(() => {
      this.$buefy.toast.open({
        message: `${this.$t('AppKeyCopied')}: <b>#${key}</b>`,
        position: 'is-bottom',
        type: 'is-info',
      });
    }).catch((err: any) => {
      throw err;
    });
  }

  private onPageChange(page: number) {
    this.page = page;
    this.loadAsyncData();
  }

  private onSort(field: string, order: string) {
    this.sortField = field;
    this.sortOrder = order;
    this.loadAsyncData();
  }

  private onProduction(appKey: string) {
    if (this.organization == null) {
      this.$buefy.toast.open({
        message: this.$t('OrgNotSet').toString(),
        position: 'is-bottom',
        type: 'is-danger',
      });
      return;
    }

    this.loadingButton = true;

    MerchantAPI
        .requestToProduction(appKey, this.organization)
        .then(() => {
          this.$buefy.toast.open({
            message: this.$t('RequestProdSent').toString(),
            position: 'is-bottom',
            type: 'is-success',
          });

          this.loadAsyncData();
        })
        .catch((err: any) => {
          this.$buefy.toast.open({
            message: err.response.data.message ?? err.message,
            position: 'is-bottom',
            type: 'is-danger',
          });
        })
        .finally(() => this.loadingButton = false);
  }

  private onDestroy(appKey: string) {
    this.$buefy.dialog.confirm({
      title: this.$t('DeletingAppHdr').toString(),
      message: this.$t('DeletingAppMsg').toString(),
      confirmText: this.$t('DeletingAppConfirm').toString(),
      cancelText: this.$t('Cancel').toString(),
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => {
        MerchantAPI.applicationDestroy(appKey).then((_: any) => {
          this.loadAsyncData();
        }).catch((err: any) => {
          this.$buefy.toast.open({
            message: err.response?.data.error || err.toString(),
            position: 'is-bottom',
            type: 'is-danger',
          });
        });
      },
    });
  }

  private loadAsyncData() {
    this.isLoading = true;

    MerchantAPI
        .applications({
          page: this.page,
          per_page: this.perPage,
          sort_field: this.sortField,
          sort_order: this.sortOrder,
        })
        .then((res: ApplicationResponse) => {
          this.apps = [];
          this.total = res.meta.total;
          this.apps = res.payload.filter((app) => !app.deleted);
        }).catch((err: any) => {
          this.apps = [];
          this.total = 0;
          this.$buefy.toast.open({
            message: err.response?.data.error || err.toString(),
            position: 'is-bottom',
            type: 'is-danger',
          });
        }).finally(() => {
          this.isLoading = false;
        });
  }
}
